.upload {
  /* margin: 20px 0px; */
  text-align: left;
  /* display: block; */
}

.switch {
  /* margin: 20px 0px; */
  text-align: left;
  /* display: block; */
}

.ant-switch-checked {
  background-color: red;
}
