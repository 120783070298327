.CreateIssue {
  text-align: center;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  /* background: #fff; */
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  /* background-color: #fafafa; */
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  /* background-color: rgba(255, 255, 255, 0.04); */
  /* color: rgba(255, 255, 255, 0.65); */
  padding-top: 80px;
}
