/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 18vw;
  margin-right: 8px;
}

.submit {
  margin-top: 20px;
}
