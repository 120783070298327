.logo-small {
  display: block;
  width: 75px;
  margin: auto;
  padding: 5px;
}

.logo-normal {
  width: 110px;
  display: block;
  margin: auto;
  padding: 5px;
}
